import React from 'react';
import PropTypes from 'prop-types';
import {IS_UNDER_13_COOKIE_NAME, setIs13OrOverCookie, setIsStudentCookie} from './util';
import {SECTION_AGE_GATE_FAIL_CREATE_ACCOUNT} from './index';

const isCookieSet = name => {
  const regex = new RegExp(`(^|;)\\s*${name}=[^;]+`);
  return !!document.cookie.match(regex);
};

const AgeGateUserType = ({setAgeGatePassed, setAgeGateVerifyDOB, registerTitle, setSection, setRegistrationUserType}) => {

  const onSelectChild = () => {
    if (window.Edu && window.Edu.trackEvent) {
      window.Edu.trackEvent('Selected User Type', {userType: 'student'});
    }
    window.Edu.registrationUserType='student';
    setRegistrationUserType('student');
    // Skip AgeGateVerifyDOB if we have an un-expired isUnder13 cookie set to true, directly display the
    // AgeGateFailCreateAccount warning popup page.
    // EDENG-361
    if (isCookieSet(IS_UNDER_13_COOKIE_NAME)) {
      setSection(SECTION_AGE_GATE_FAIL_CREATE_ACCOUNT);
    }
    else {
      setIsStudentCookie();
      setAgeGateVerifyDOB(true);
    }
  };

  const onSelectParent = () => {
    if (window.Edu && window.Edu.trackEvent) {
      window.Edu.trackEvent('Selected User Type', {userType: 'parent'});
    }
    const parentRadio = document.querySelector('.iama input[value="parent"]');
    if (parentRadio) {
      window.Edu.registrationUserType='parent';
      parentRadio.checked = true;
    }
    setRegistrationUserType('parent');
    setIs13OrOverCookie();
    setAgeGatePassed(true);
  };

  const onSelectTeacher = () => {
    if (window.Edu && window.Edu.trackEvent) {
      window.Edu.trackEvent('Selected User Type', {userType: 'teacher'});
    }
    const teacherRadio = document.querySelector('.iama input[value="teacher"]');
    if (teacherRadio) {
        window.Edu.registrationUserType='teacher';
        teacherRadio.checked = true;
    }
    setRegistrationUserType('teacher');
    setIs13OrOverCookie();
    setAgeGatePassed(true);
  };

  // RENDER
  return (
    <div className="create-account">
      <div className="signup-age-gate date-of-birth-age-gate-container">
        <div className="date-of-birth-age-gate">
          <h3>{registerTitle}</h3>
          <div className="age-gate-title">Step 1: Who is primarily going to use this content?</div>
          <div className="user-type-container">
            <div className="user-type user-type-parent" onClick={onSelectParent}>
              <div className="user-type-name">
                My Child
              </div>
              <p>I'm a parent or guardian</p>
            </div>
            <div className="user-type user-type-teacher" onClick={onSelectTeacher}>
              <div className="user-type-name">
                My Students
              </div>
              <p>I'm a teacher</p>
            </div>
            <div className="user-type user-type-child" onClick={onSelectChild}>
              <div className="user-type-name">
                Myself
              </div>
              <p>I'm a student</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
AgeGateUserType.propTypes = {
  setAgeGatePassed: PropTypes.func.isRequired,
  setAgeGateVerifyDOB: PropTypes.func.isRequired,
  registerTitle: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
  setRegistrationUserType: PropTypes.func.isRequired
};
export default AgeGateUserType;
