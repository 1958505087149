import {DEFAULT_ANALYTICS_NAME} from './index';

export const IS_13_OR_OVER_COOKIE_NAME = 'is13OrOver';
export const IS_STUDENT_COOKIE_NAME = 'isStudent';
export const IS_UNDER_13_COOKIE_NAME = 'isUnder13';

export function launchIfSwitchAccountCookieSet() {
  const name = 'switchAccounts';
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    const email = match[2];
    // If user clicked "log in with different email" in my accounts modal, email will be set to '0'
    if (email !== '0' && email !== 'social') {
      window.Edu.openLogin({section: 'login', account: email});
    } else {
      window.Edu.openLogin({section: 'login'});
    }
    document.cookie = 'switchAccounts=; expires=' + new Date().toUTCString() + '; path=/';
  }
}

export function getCookieExpirationDate() {
  const d = new Date();
  d.setTime(d.getTime() + (15 * 60 * 1000));
  return d.toUTCString();
}

function setAgeGateCookie(name) {
  document.cookie = `${name}=1; expires=${getCookieExpirationDate()}; path=/`;
}

function deleteAgeGateCookie(name) {
  document.cookie = `${name}=1; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
}

export function setIs13OrOverCookie() {
  setAgeGateCookie(IS_13_OR_OVER_COOKIE_NAME);
  deleteAgeGateCookie(IS_STUDENT_COOKIE_NAME);
  deleteAgeGateCookie(IS_UNDER_13_COOKIE_NAME);
}

export function setIsStudentCookie() {
  setAgeGateCookie(IS_STUDENT_COOKIE_NAME);
  deleteAgeGateCookie(IS_13_OR_OVER_COOKIE_NAME);
  deleteAgeGateCookie(IS_UNDER_13_COOKIE_NAME);
}

export function setIsUnder13Cookie() {
  setAgeGateCookie(IS_UNDER_13_COOKIE_NAME);
  deleteAgeGateCookie(IS_13_OR_OVER_COOKIE_NAME);
  deleteAgeGateCookie(IS_STUDENT_COOKIE_NAME);
}

function customEventPolyfill() {
  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  window.CustomEvent = CustomEvent;
}

export function dispatchCustomEvent(name) {
  customEventPolyfill();

  const event = new CustomEvent(name);
  document.dispatchEvent(event);

}

export function trackRegistration(event, name, method, gaDimensions) {
  if (window.Edu && window.Edu.trackEvent) {
    const data = {section: DEFAULT_ANALYTICS_NAME, trigger: "topnav"};
    if (method) data.method = method;

    window.Edu.trackEvent(name, data, {
      eventCategory: 'modal',
      eventAction: `${DEFAULT_ANALYTICS_NAME} registration modal: ${event}`
    },
      gaDimensions
    );
  }
}

export function polyFillPadStart() {
  if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
      targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
      padString = String(typeof padString !== 'undefined' ? padString : ' ');
      if (this.length >= targetLength) {
        return String(this);
      } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
          padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(this);
      }
    };
  }
}
