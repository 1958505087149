import { loadScript } from '../../../util';

class GoogleSSO {
  constructor({ sdkUrl, scope, clientId }) {
    this.sdkUrl = sdkUrl;
    this.scope = scope;
    this.clientId = clientId;
  }

  prepare = () => {
    const {sdkUrl} = this;
    return loadScript(sdkUrl);
  }

  login = () => {
    const {clientId,scope} = this;
    return new Promise((resolve, reject) => {
      this.codeClient = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: scope,
        callback: (resp) => {
          resolve(resp);
        }});

      this.codeClient.requestCode();
      }
    )}

  logout = () => {
    return new Promise((resolve, reject) => {
      // Nothing to do here, just log out.
      resolve();
    });
  }
}
export default GoogleSSO;
