import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Image from '../../components/Image';

const AdditionalInfo = ({setIsLoading, availableGrades, ordinalGrades, registrationUserType}) => {
    const SELECTED = 'selected';
    // Whether "parent" was chosen in AgeGateUserType section.
    let isParent = ['parent', ''].includes(registrationUserType);
    let selectedGrades = new Map();

    const onSubmitAdditionalInfo = async (e) => {
        e.preventDefault();

        let formData = {
            selectedTypes: [],
            selectedGrades: [],
            schoolName: ''
        };

        if (isParent) {
            // When "Parent" account type is selected on previous AgeGateUserType page, and "I'm a homeschooler"
            // checkbox checked on current page, set the account type to "homeschooler".
            const homeschoolOption = e.target['homeschool'];
            formData.selectedTypes.push(homeschoolOption.checked ? homeschoolOption.value : 'parent');
        } else {
            // If user has chosen "teacher" or "student" in AgeGateUserType section, align to selected type
            formData.selectedTypes.push(registrationUserType)
        }

        // Extract selected grades info.
        if (selectedGrades.size > 0) {
            selectedGrades.forEach((value, key) => {
                formData.selectedGrades.push(key);
            });
        }

        if (e.target.schoolName && e.target.schoolName.value) {
            formData.schoolName = e.target.schoolName.value;
        }

        setIsLoading(true);
        await axios.post('/api/member/additionalRegistrationInfo', {data: formData});
        window.location = window.location;
    };

    const onClickUpdateButtonState = (grade, e) => {
        let classList = e.target.classList;
        if (classList.contains(SELECTED)) {
            classList.remove(SELECTED);
            if (selectedGrades.get(grade)) {
                selectedGrades.delete(grade);
            }
        }
        else {
            classList.add(SELECTED);
            selectedGrades.set(grade, true);
        }
    }

    return (
        <div className="additional-info">
            { isParent
              ? <h2 className="parent-title">Tell us about yourself</h2>
              : <h3 className="teacher-title">What grades are you interested in?</h3> }
            <form className="form registration-form" noValidate onSubmit={onSubmitAdditionalInfo}>
                <div className="input-row grades">
                    { isParent && <h4 className="interested-grades">I'm interested in grades:</h4> }
                    <div className="buttons-wrapper">
                        {availableGrades.map((grade, index) => (
                          <React.Fragment key={grade}>
                            <div className="solid-button">
                                <div className={"option " + grade} value={grade} onClick={(e)=>{onClickUpdateButtonState(grade, e)}}>
                                    {ordinalGrades[index]}
                                </div>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="errormsg"/>
                </div>

                { isParent &&
                    <div className="homeschool-option">
                        <span>I'm a homeschooler</span>
                        <label className="checkbox">
                            <input type="checkbox" value="homeschooler" name="homeschool" required />
                            <div className="checkbox"/>
                        </label>
                    </div>}
                
                <div className="more-info">You can change these at any time in your Account Settings.</div>

                <div className="btn-container">
                    <button className="btn btn-primary" type="submit">Get Started!</button>
                </div>
            </form>
            <Image alt="Roly" className="roly-kid" src="https://cdn.education.com/files/static/reg-modal/roly-kid.png"/>
        </div>
    )
};

AdditionalInfo.propTypes = {
    setIsLoading: PropTypes.func,
    // List of grades. These are in the "kebab" format.
    availableGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
    // List of the corresponding ordinal display version of the grades (1st, 2nd, etc.)
    ordinalGrades: PropTypes.arrayOf(PropTypes.string).isRequired,
    registrationUserType: PropTypes.string.isRequired
};

AdditionalInfo.defaultProps = {
    setIsLoading: function () {},
};

export default AdditionalInfo;
