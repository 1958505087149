import React, {useState, useLayoutEffect, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Signup from './Signup';
import Login from './Login';
import ResetPassword from './ResetPassword';
import SwitchAccounts from './SwitchAccounts';
import AdditionalInfo from './AdditionalInfo';
import '../../../../themes/sky/css/Registration.css.less';
import Modal from '../../components/Modal';
import SpinningWheel from '../../components/SpinningWheel';
import {listen} from '../../common/util'
import AgeGateUserType from './AgeGateUserType';
import AgeGateEnterAge from './AgeGateEnterAge';
import ChangePassword from './ChangePassword';
import {IS_13_OR_OVER_COOKIE_NAME, IS_UNDER_13_COOKIE_NAME, launchIfSwitchAccountCookieSet, dispatchCustomEvent} from './util';
import AgeGateFail from './AgeGateFail';
import gradeMap from '../../common/gradeMap';
import AgeGateFailCreateAccount from "./AgeGateFailCreateAccount";
import grades from '../../common/grades';

export const SECTION_LOGIN = 'login';
export const SECTION_SIGNUP = 'signup';
export const SECTION_RESET = 'reset';
export const SECTION_SWITCH = 'switch';
export const SECTION_CHANGE_PASSWORD = 'change';
export const SECTION_AGE_GATE_FAIL = 'ageGateFail';
export const SECTION_AGE_GATE_FAIL_CREATE_ACCOUNT = 'ageGateFailCreateAccount';
export const SECTION_ADDITIONAL_INFO = 'additionalInfo';

export const DEFAULT_REGISTER_TITLE = 'Create Account';
export const DEFAULT_REGISTER_DESCRIPTION = '';
export const DEFAULT_ANALYTICS_NAME = 'React';
export const DEFAULT_REGISTER_BUTTON_TEXT = 'Create Account';
export const DEFAULT_SIGNIN_DESCRIPTION = '';
export const DEFAULT_SIGNIN_TITLE = 'Log In';
export const DEFAULT_SIGNIN_TITLE_PAGE = 'Log In to Continue';
export const REGMODAL_READY_EVENT = 'regModalReady';
const ACCOUNT_INFO = 'accountInfo';

const useSeedPropsOnReactStart = (email, popup, mode) => {
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState('');
  const [account, setAccount] = useState('');
  const appOverrides = useRef({signIn: {}, signUp: {}});

  useLayoutEffect(() => {
    if (popup === "true") {
      listen('openRegModal', (detail) => {
        const {section: openSection, account: defaultAccount, trigger, ...overrideProps} = detail;

        appOverrides.current = {...appOverrides.current, ...overrideProps, trigger};
        setOpen(true);
        setAccount(defaultAccount || '');
        if (trigger !== 'topnav' && Cookies.get(IS_UNDER_13_COOKIE_NAME)) {
          setSection(SECTION_AGE_GATE_FAIL);
        }
        else {
          setSection(openSection);
        }
      });
    }
    else {
      const trigger = "";
      const overrideProps = {signIn: {title: DEFAULT_SIGNIN_TITLE_PAGE}};
      appOverrides.current = {...appOverrides.current, ...overrideProps, trigger};

      setOpen(true);
      setAccount(email || '');
      setSection(mode);
    }

  }, []);

  return [open, setOpen, section, setSection, appOverrides, account, setAccount];
};

const useListenForGdprStatus = () => {
  const [inGdpr, setInGdpr] = useState(window.Edu.gdpr ? window.Edu.gdpr.inGDPRCountry : false);
  const [gdprCookieDisabled, setGdprCookieDisabled] = useState(window.Edu.gdpr ? window.Edu.gdpr.disabledCookieInGDPR : false);

  useEffect(() => {
    listen('gdprStatus', (data) => {
        setInGdpr(data.inGDPRCountry);
        setGdprCookieDisabled(data.disabledCookieInGDPR);
      }
    );
  });

  return [inGdpr, gdprCookieDisabled];
};

const useHandleQueryString = (setIsOpen, setSection) => {
  useEffect(() => {
    if (window.Edu.queryString) {
      if (window.Edu.queryString['reset-password'] && window.Edu.queryString['key']) {
        setIsOpen(true);
        setSection(SECTION_RESET);
      }
    }
  }, []);
};

const useSkipAgeGateIfOver13 = (setAgeGatePassed, setAgeGateVerifyDOB) => {
  useLayoutEffect(() => {
    if (Cookies.get(IS_13_OR_OVER_COOKIE_NAME)) {
      setAgeGatePassed(true);
      setAgeGateVerifyDOB(false);
    }
  }, []);
};

const useDefaultToUserTypeSelectorOnReOpenModal = (appOverrides, setAgeGateVerifyDOB, isOpen) => {
  useLayoutEffect(() => {
    if (appOverrides.current.signUp.showUserType) {
      setAgeGateVerifyDOB(false);
    }
  }, [isOpen]);
};

const useCoppaForceAgeGateIfStudent = (appOverrides, setAgeGateVerifyDOB, isOpen) => {
  useLayoutEffect(() => {
    if (appOverrides.current.signUp.forceAgeGate && Cookies.get('isStudent')) {
      setAgeGateVerifyDOB(true);
    }
  }, [isOpen]);
};

const RegistrationModal = ({regModalEmailPreferences, email, popup, mode}) => {
  const [ageGatePassed, setAgeGatePassed] = useState(false);
  const [ageGateVerifyDOB, setAgeGateVerifyDOB] = useState(false);
  const [isOpen, setIsOpen, section, setSection, appOverrides, account, setAccount] =
    useSeedPropsOnReactStart(email, popup, mode);
  const [inGdpr, gdprCookieDisabled] = useListenForGdprStatus();
  const [isLoading, setIsLoading] = useState(false);
  const [socialLoginError, setSocialLoginError] = useState('');
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const storedUsers = useRef([]);
  const [registrationUserType, setRegistrationUserType] = useState('');

  // Read grades from gradeMap.json
  const availableGrades = grades.availableGrades.map((grade) => {
    const camelString = gradeMap[grade].camel;
    return camelString.charAt(0).toUpperCase() + camelString.slice(1);
  });
  const ordinalGrades = availableGrades.map((grade) => gradeMap[grade].ordinalDisplay);

  useEffect(() => {
    function getStoredAccounts() {
      const accountsLocalStorage = JSON.parse(window.localStorage.getItem(ACCOUNT_INFO));
      if (accountsLocalStorage && accountsLocalStorage.length) {
        storedUsers.current = accountsLocalStorage;
      }
    }

    getStoredAccounts();
  }, []);

  useEffect(() => {
    launchIfSwitchAccountCookieSet();
  }, []);

  useSkipAgeGateIfOver13(setAgeGatePassed, setAgeGateVerifyDOB);
  useDefaultToUserTypeSelectorOnReOpenModal(appOverrides, setAgeGateVerifyDOB, isOpen);
  useCoppaForceAgeGateIfStudent(appOverrides, setAgeGateVerifyDOB, isOpen);
  useHandleQueryString(setIsOpen, setSection);

  useEffect(() => {
    dispatchCustomEvent(REGMODAL_READY_EVENT);
  }, []);

  // RENDER

  let page = null;
  if (section === SECTION_LOGIN) {
    page = (<Login
      setSection={setSection}
      defaultAccount={account}
      setIsLoading={setIsLoading}
      signInDescription={appOverrides.current.signIn.description || DEFAULT_SIGNIN_DESCRIPTION}
      signInTitle={appOverrides.current.signIn.title || DEFAULT_SIGNIN_TITLE}
      generalError={socialLoginError}
      setGeneralError={setSocialLoginError}
      storedUsers={storedUsers}
      setAccount={setAccount}
    />);
  } else if (section === SECTION_CHANGE_PASSWORD) {
    page = (<ChangePassword
      setIsOpen={setIsOpen}
      setIsLoading={setIsLoading}
      setChangePasswordModal={setChangePasswordModal}
      setSection={setSection} />)
  } else if (section === SECTION_RESET) {
    page = (<ResetPassword
      setSection={setSection}
      setIsLoading={setIsLoading}
      setIsOpen={setIsOpen}
      changePasswordModal={changePasswordModal}
    />)
  } else if (section === SECTION_SWITCH) {
    page = <SwitchAccounts
      storedUsers={storedUsers}
      setSection={setSection}
      setAccount={setAccount}
      setSocialLoginError={setSocialLoginError}
      setIsLoading={setIsLoading}/>
  } else if (section === SECTION_ADDITIONAL_INFO) {
    page = (<AdditionalInfo
      appOverrides={appOverrides}
      setIsLoading={setIsLoading}
      availableGrades={availableGrades}
      ordinalGrades={ordinalGrades}
      registrationUserType={registrationUserType}
    /> )
  } else if (section === SECTION_AGE_GATE_FAIL) {
    page = <AgeGateFail/>
  } else if (section === SECTION_AGE_GATE_FAIL_CREATE_ACCOUNT) {
    // If the student is under 13 and trying to create an account, show the AgeGateFailCreateAccount popover window.
    // EDENG-361.
    page = <AgeGateFailCreateAccount/>
  } else if (ageGateVerifyDOB) {
    page = <AgeGateEnterAge
      setIsOpen={setIsOpen}
      appOverrides={appOverrides}
      setAgeGateVerifyDOB={setAgeGateVerifyDOB}
      setSection={setSection}
      setAgeGatePassed={setAgeGatePassed}/>
  } else if (ageGatePassed || appOverrides.current.signUp.skipAgeGate) {
    page = (<Signup
      registerTitle={appOverrides.current.signUp.title || DEFAULT_REGISTER_TITLE}
      setSection={setSection}
      inGDPR={inGdpr}
      gdprCookieDisabled={gdprCookieDisabled}
      registerDescription={appOverrides.current.signUp.description || DEFAULT_REGISTER_DESCRIPTION}
      registerButtonText={appOverrides.current.signUp.button || DEFAULT_REGISTER_BUTTON_TEXT}
      gdprPreferences={regModalEmailPreferences}
      setAccount={setAccount}
      defaultAccount={account}
      setIsOpen={setIsOpen}
      additionalInfoSection={SECTION_ADDITIONAL_INFO}
      setIsLoading={setIsLoading}
      registrationUserType={registrationUserType}/>);
  } else {
    page = <AgeGateUserType
      registerTitle={appOverrides.current.signUp.title || DEFAULT_REGISTER_TITLE}
      setAgeGatePassed={setAgeGatePassed}
      setAgeGateVerifyDOB={setAgeGateVerifyDOB}
      setSection={setSection}
      setRegistrationUserType={setRegistrationUserType}/>
  }

  let result;

  if (popup === "true") {
    result = (
      <Modal open={isOpen} onClose={() => {

        // If close on the additional info section, then just refresh
        if (section === 'additionalInfo') {
          setIsLoading(true);
          window.location = window.location;
          return;
        }

        if (appOverrides.current.onClose && typeof appOverrides.current.onClose === 'function') {
          appOverrides.current.onClose();
        }
        else {
          setAgeGateVerifyDOB(false);
          setIsOpen(false);
        }
      }}>
        {isLoading &&
        <SpinningWheel fullScreen/>
        }
        <div className="react-registration">
          {page}
        </div>
      </Modal>
    )
  }
  else {
    result = (
      <div className="react-registration-page">
        {isLoading &&
        <SpinningWheel fullScreen/>
        }
        <div className="react-registration-div">
          {page}
        </div>
      </div>
    )
  }

  return result;
};

RegistrationModal.propTypes = {
  regModalEmailPreferences: PropTypes.shape({
    Surveys: PropTypes.string.isRequired,
    Assignments: PropTypes.string.isRequired,
    MarketingNewsletter: PropTypes.string.isRequired,
    ContentNewsletter: PropTypes.string.isRequired
  }).isRequired,
  email: PropTypes.string,
  popup: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired
};

RegistrationModal.defaultProps = {
  popup: "true",
  mode: SECTION_SWITCH
};

export default RegistrationModal;
