import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import GoogleLoginEdCom from './GoogleLoginEdCom';
import FacebookLogin from './FacebookLogin';
import {SECTION_LOGIN} from './index';

const Account = ({email, label, social, chooseAccount, setSocialLoginError,
                   setSection, setIsLoading, logOut }) => {

  const onLoginSuccess = () => {
    window.setTimeout(() => {
      if (window.buyflowRedirectOnSuccess) {
        const url = window.buyflowRedirectOnSuccess;
        window.buyflowRedirectOnSuccess = null;
        window.location.href = url;
      } else {
        window.location.reload();
      }
    }, 200);
  };

  const onLoginFailure = (errorMessage) => {
    setIsLoading(false);
    setSocialLoginError(errorMessage);
    setSection(SECTION_LOGIN);
  };

  let loggedInAccount = window.Edu.email === email;

  const onSelectAccount = () => {
    if (logOut && !loggedInAccount) {
      chooseAccount(email);
    }
    else if (!logOut && !social) {
      chooseAccount(email);
    }
  };

  return (
    <div
      className={clsx('account-container ', social && 'choose-account-link')}
      data-email={email}
      role="menuitem"
      onKeyPress={onSelectAccount
      }
      onClick={onSelectAccount}>
      <div className={clsx({'account': !social, 'social-account': social, 'logged-in': loggedInAccount, 'clickable-social': social && logOut})}>
        <div className="account__icon">
          <svg className="user-icon"
               width="50" height="50" viewBox="0 0 24 24">
            <path
              d="M12 5.9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2zm0 9c2.97 0 6.1 1.46
               6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1zM12 4C9.79 4 8 5.79 8 8s1.79
               4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8
               4v3h16v-3c0-2.66-5.33-4-8-4z"/>
          </svg>
        </div>
        <div className="account__info">
          <div className="account__email">
            {email}
          </div>
          {label}
          {loggedInAccount && <div className="account__info__logged-in">Logged In </div>}
          {/* add in these properties */}
          {(social === 'google' && !logOut) && <div className="buttonWrapper"><GoogleLoginEdCom
            onFailure={onLoginFailure}
            setIsLoading={setIsLoading}
            onSuccess={onLoginSuccess}
            width="200"/></div>}
          {(social === 'facebook' && !logOut) && <FacebookLogin
            onFailure={onLoginFailure}
            setIsLoading={setIsLoading}
            text="Sign in with Facebook"
            onSuccess={onLoginSuccess}/>}
        </div>
      </div>
    </div>
  );
};
Account.propTypes = {
  email: PropTypes.string.isRequired,
  label: PropTypes.element.isRequired,
  social: PropTypes.string.isRequired,
  chooseAccount: PropTypes.func.isRequired,
  logOut: PropTypes.bool,
  setSocialLoginError: PropTypes.func,
  setSection: PropTypes.func,
  setIsLoading: PropTypes.func,
};
export default Account;
