import React, {useState, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AccountLabel from './AccountLabel';
import Account from './Account';
import {SECTION_LOGIN} from './index';
import {trackRegistration} from './util';

const GOOGLE = 'google';
const FACEBOOK = 'facebook';

const SwitchAccounts = ({setSection, setAccount, setSocialLoginError, setIsLoading, storedUsers, logOut}) => {
  const [userList, setUserList] = useState([]);
  const [facebookList, setFacebookList] = useState([]);
  const [googleList, setGoogleList] = useState([]);

  const setSwitchCookieAndLogout = (email) => {
    if (typeof email === 'undefined') email = null;
    if (email) {
      document.cookie = 'switchAccounts='+email+'; expires=1 Jan 2988 00:00:11 UTC; path=/';
      if (window.Edu && window.Edu.trackEvent) {
        const social = email === 'social' ? ' (social)' : '';
        window.Edu.trackEvent('My accounts modal',{section: 'Responsive', method: 'switch accounts link clicked' + social},{
          eventCategory: 'modal',
          eventAction: 'Responsive my accounts modal: switch accounts link clicked' + social
        });
      }
    } else {
      // Clicked "log in with different email"
      document.cookie = 'switchAccounts=0; expires=1 Jan 2988 00:00:11 UTC; path=/';
      if (window.Edu && window.Edu.trackEvent) {
        window.Edu.trackEvent('My accounts modal',{section: 'Responsive', method: 'log in with different email clicked'},{
          eventCategory: 'modal',
          eventAction: 'Responsive my accounts modal: log in with different email clicked'
        });
      }
    }
    window.location.href = '/?__logout'; //might need to look at this line for caching issues

  };

  const onSelectAccount = (email) => {
    if (logOut) {
      setSwitchCookieAndLogout(email);
    }
    else {
      setAccount(email);
      trackRegistration('choose account link clicked', 'Choose account link');
      trackRegistration('opened', 'Viewed Registration Form', 'login');
      setSection(SECTION_LOGIN);
    }
  };

  const populateAccounts = (localAccounts) => {
    const googleAccounts = [];
    const facebookAccounts = [];
    const processedUsers = [];

    if (Array.isArray(localAccounts)) {
      // Only show the first 5 accounts (ordered by last login date)
      localAccounts = localAccounts.slice(0,5);

      localAccounts.forEach((account) => {
        const label = <AccountLabel account={account} />;
        if (Array.isArray(account.socialSignupTypes) && account.socialSignupTypes.length) {
        account.socialSignupTypes.forEach((socialType)=> {
            if (socialType === GOOGLE) {
              googleAccounts.push(<Account
                  email={account.email}
                  label={label}
                  social={GOOGLE}
                  chooseAccount={onSelectAccount}
                  key={account.email}
                  setSocialLoginError={setSocialLoginError}
                  setSection={setSection}
                  setIsLoading={setIsLoading}
                  logOut={logOut}
                />
              );
            } else if (socialType === FACEBOOK) {
              facebookAccounts.push(
                <Account
                  email={account.email}
                  label={label}
                  social={FACEBOOK}
                  chooseAccount={onSelectAccount}
                  key={account.email}
                  setSocialLoginError={setSocialLoginError}
                  setSection={setSection}
                  setIsLoading={setIsLoading}
                  logOut={logOut}
                />
              );
            }})}

        if (!account.socialSignupTypes || account.socialSignupTypes.length === 0) {
          processedUsers.push(
            <Account
              email={account.email}
              label={label}
              social=""
              chooseAccount={onSelectAccount}
              key={account.email}
              setSocialLoginError={setSocialLoginError}
              setSection={setSection}
              setIsLoading={setIsLoading}
              logOut={logOut}
            />
          );
        }
      });
    }

    setUserList(processedUsers);
    setFacebookList(facebookAccounts);
    setGoogleList(googleAccounts);
  };

  useLayoutEffect(() => {
    function populateAccountsOrReturnToLogin() {
      if (storedUsers.current && storedUsers.current.length) {
        populateAccounts(storedUsers.current);
      } else {
        trackRegistration('opened', 'Viewed Registration Form', 'login');
        setSection(SECTION_LOGIN);
      }
    }

    populateAccountsOrReturnToLogin();
  }, []);

  // RENDER
  return (
    <div className="choose-account">
      <h3>Choose an Account to Log In</h3>
      <div className="accounts">
        {userList}
        <div className={clsx('account-container google-accounts ', googleList.length === 0 && 'hidden')}>
          <div className="account-container-header loginwith smaller-social-btns">Google accounts</div>
            <div className="register-with-google g-signin2" data-width="480" data-height="40"
                 data-longtitle="true"
                 data-scope="profile email" data-theme="light" data-onsuccess="googleSignIn"
                 data-onfailure="googleError" data-type="login"/>
          {googleList}
        </div>
        <div className={clsx('account-container facebook-accounts ', facebookList.length === 0 && 'hidden')}>
          <div className="account-container-header loginwith smaller-social-btns">Facebook accounts</div>
          {facebookList}
        </div>
      </div>
      <button type="button" className="btn btn-secondary btn-block" onClick={() => {
        if (logOut) {
          onSelectAccount();
          return;
        }
        setAccount("");
        trackRegistration('opened', 'Viewed Registration Form', 'login');
        trackRegistration('skip choose account link clicked', 'Skip choose account link');
        setSection(SECTION_LOGIN)
      }}>Log in with different email</button>
      <div className="cs-disclaimer">For more assistance contact <a href="http://support.education.com/"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">customer service</a></div>
    </div>);
};

SwitchAccounts.propTypes = {
  storedUsers: PropTypes.oneOfType([
    PropTypes.object, // for legacy refs
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  logOut: PropTypes.bool,
  setSection: PropTypes.func,
  setAccount: PropTypes.func,
  setSocialLoginError: PropTypes.func,
  setIsLoading: PropTypes.func,
};
SwitchAccounts.defaultProps = {
  logOut: false,
  setSection: function () {},
  setAccount: function () {},
  setSocialLoginError: function () {},
  setIsLoading: function () {},
};

export default SwitchAccounts;
