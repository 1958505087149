import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import classNames from 'classnames';
import FacebookLogin from './FacebookLogin';
import GoogleLoginEdCom from './GoogleLoginEdCom';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {SECTION_SWITCH} from './index';
import {trackRegistration, dispatchCustomEvent} from './util';

const ERROR_MESSAGE_ENTER_EMAIL_ADDRESS = 'Please enter your email address.';
const ERROR_MESSAGE_ENTER_PASSWORD = 'Please enter your password.';
const ERROR_MESSAGE_EXISTING_ACCOUNT = 'That email is already registered with Education.com,' +
  ' but the password is different.';
const ERROR_MESSAGE_INVALID_EMAIL = 'Invalid Email Address';
const ERROR_MESSAGE_GENERAL_FAILURE = 'There was an error creating your account.';
const ERROR_MESSAGE_PASSWORD_SHORT = 'Please enter a password at least 6 characters long.';
const ERROR_MESSAGE_PASSWORD_LONG = 'Please enter a password less than 32 characters.';
const HAS_ERROR_CLASS_NAME = 'has-error';
const ERROR_MESSAGE_GDPR_COOKIES = 'You need enable cookies in order to create an account.';

const Signup = ({defaultAccount, setIsLoading, registerButtonText, registerDescription, registerTitle, setSection,
                  gdprPreferences, inGDPR, setAccount, setIsOpen, additionalInfoSection, registrationUserType}) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailChecksTotal, setEmailChecksTotal] = useState(0);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [generalErrorMessage, setGeneralErrorMessage] = useState("");
  const gdprInput = useRef(null);
  const [gdprErrorMessage, setGdprErrorMessage] = useState(null);

  useEffect(() => {
    if (defaultAccount) {
      setEmail(defaultAccount);
    }
  }, []);

  const onSignupSuccess = (existingLogin = false) => {
    if (!existingLogin) {
      setIsLoading(false);
      dispatchCustomEvent('member_registration');

      // Show the additional info section
      setSection(additionalInfoSection);
    }
    else {
      window.setTimeout(() => {
        if (window.buyflowRedirectOnSuccess) {
          const url = window.buyflowRedirectOnSuccess;
          window.buyflowRedirectOnSuccess = null;
          window.location.href = url;
        }
        else {
          window.location.reload();
        }
      },  100);
    }
  };

  const onSignupFailure = (response={}) => {
    trackRegistration('Signup failure', 'failed signup');
    setIsLoading(false);
    if (response.data) {
      if (response.data.exists) {
        setPasswordErrorMessage(ERROR_MESSAGE_EXISTING_ACCOUNT);
      } else if (response.data.errors.emailaddress) {
        setEmailErrorMessage(ERROR_MESSAGE_INVALID_EMAIL);
      } else if (response.data.errors.password) {
        setPasswordErrorMessage(response.data.errors.password);
      } else if (response.data.errors.membername) {
        setEmailErrorMessage(response.data.errors.membername);
      } else if (response.data.errors.gdprOptIn) {
        setGdprErrorMessage(response.data.errors.gdprOptIn);
      }
    }
    // Error unrelated to a specific field
    else {
      setGeneralErrorMessage(ERROR_MESSAGE_GENERAL_FAILURE);
    }
  };

  function getGdprMailingListPreferences() {
    const emailPreferences = [];

    if (inGDPR) {
      Object.keys(gdprPreferences).forEach((key) => {
        const prefInput = document.getElementById(key);
        if (prefInput.checked) {
          emailPreferences.push(prefInput.value);
        }
      })
    }
    return emailPreferences;
  }

  function populateRegisterForm(emailPreferences, isEmailRisky) {
    const registerFormData = new FormData();
    registerFormData.append("emailaddress", email);
    registerFormData.append("grades[]", []);
    registerFormData.append("password", password);
    registerFormData.append("type[]", [registrationUserType]);
    registerFormData.append("jsfinished", 1);
    registerFormData.append("shortreg", 0);
    registerFormData.append("gdprOptIn", gdprInput.current && gdprInput.current.checked ? 1 : 0);
    registerFormData.append("emailPreferences", emailPreferences);
    registerFormData.append("csrfToken", window.Edu.csrfToken);
    registerFormData.append("isEmailRisky", isEmailRisky.toString());
    return registerFormData;
  }

  const createAccount = async (isEmailRisky) => {
    const emailPreferences = getGdprMailingListPreferences();
    const registerFormData = populateRegisterForm(emailPreferences, isEmailRisky);

    try {
      const response = await axios.post('/api/member/register', registerFormData);
      if (response.data.status === "1") {
        const gaDimensions = 'ga_dimensions' in response.data ? response.data.ga_dimensions : null;
        trackRegistration('signup success', 'Registered', null, gaDimensions);
        onSignupSuccess();
      }
      else {
        onSignupFailure(response);
      }
    }
    catch {
      onSignupFailure();
    }
  };

  function validateEmailPresence() {
    if (email.length === 0) {
      setEmailErrorMessage(ERROR_MESSAGE_ENTER_EMAIL_ADDRESS);
      return false;
    }
    return true;
  }

  async function verifyEmailByBriteVerify() {
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append("action", 'verifyEmailByBriteVerify');
    formdata.append("__json", 'Modal_Registration');
    formdata.append('csrfToken', window.Edu.csrfToken);

    const response = await axios.post('?', formdata);
    if (response.data.status === "0") {
      setEmailErrorMessage(response.data.msg);
    }
    return response.data.status;
  }

  function validatePasswordPresence() {
    if (password.length === 0) {
      setPasswordErrorMessage(ERROR_MESSAGE_ENTER_PASSWORD);
      return false;
    }
    return true;
  }

  function validatePasswordLength() {
    if (password.length > 32) {
      setPasswordErrorMessage(ERROR_MESSAGE_PASSWORD_LONG);
      return false;
    }

    if (password.length < 6) {
      setPasswordErrorMessage(ERROR_MESSAGE_PASSWORD_SHORT);
      return false;
    }
    return true;
  }

  function populateFormForLogin() {
    const bodyFormData = new FormData();
    bodyFormData.append("username", email);
    bodyFormData.append("password", password);
    bodyFormData.append("remember", 1);
    bodyFormData.append("__redirect", window.location.href);
    bodyFormData.append("jsfinished", 1);
    bodyFormData.append("gdprOptIn", gdprInput.current && gdprInput.current.checked ? 1 : 0);
    bodyFormData.append("csrfToken", window.Edu.csrfToken);
    return bodyFormData;
  }

  async function checkForExistingEmail() {
    const checkFormData = new FormData();
    checkFormData.append("email", email);
    checkFormData.append("csrfToken", window.Edu.csrfToken);

    const checkResponse = await axios.post('/api/check-email', checkFormData);
    return checkResponse;
  }

  const onSubmitNewAccount = async (e) => {
    e.preventDefault();
    // validation
    if (!validateEmailPresence()) return;

    const briteVerifyStatus = await verifyEmailByBriteVerify();
    if (briteVerifyStatus === "0") return;
    const isEmailRisky = briteVerifyStatus === '2';

    if (!validatePasswordPresence()) return;
    if (!validatePasswordLength()) return;

    const bodyFormData = populateFormForLogin();

    setIsLoading(true);
    // First, try logging in
    try {
      const loginResponse = await axios.post('/api/login', bodyFormData);
      // Successfully logged in
      if (loginResponse.data.status === "1") {
        trackRegistration('login success', 'Logged In');
        onSignupSuccess(true);
      } else {
        const checkResponse = await checkForExistingEmail();
        // Email already registered
        if (checkResponse.data.status) {
          onSignupFailure({data: {status: 0, exists: true}});
        }
        // Not registered
        else {
          createAccount(isEmailRisky);
        }
      }
    }
    catch {
      createAccount(isEmailRisky);
    }
  };


  const checkEmailAddress = () => {
    if (email.length === 0) {
      setEmailErrorMessage(null);
      return;
    }

    // limit to 20 email/mx lookups:
    if (emailChecksTotal > 20) {
      return;
    }

    setEmailChecksTotal(emailChecksTotal + 1);

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append("action", 'checkemail');
    formdata.append("__json", 'Modal_Registration');
    formdata.append('csrfToken', window.Edu.csrfToken);

    // check the MX record:
    axios.post('?', formdata)
      .then(response => {
        if (response.data.status === "0" || !response.data.status) {
          setEmailErrorMessage(response.data.msg);
        } else {
          setEmailErrorMessage("");
        }
      })
      .catch(()=> {
        setEmailErrorMessage("");
      });
  };

  // RENDER
  return (
    <div className="createAccount">
      <h3>
        {registerTitle}
      </h3>
      <p className="createAccount__description">{registerDescription}</p>
      <div className="socialButtons">
        <div className="buttonWrapper">
          <FacebookLogin text="Sign in with Facebook" onSuccess={onSignupSuccess}
                         onFailure={onSignupFailure} setIsLoading={setIsLoading}/>
          <GoogleLoginEdCom onSuccess={onSignupSuccess}
                            onFailure={onSignupFailure} setIsLoading={setIsLoading}/>
        </div>
      </div>

      <div className="font-primary-bold align-center registration__or">OR</div>

      <form className="form registration-form" onSubmit={onSubmitNewAccount}>
        <div className={clsx('input-row email-address ', emailErrorMessage && HAS_ERROR_CLASS_NAME)}>
          <label className={classNames('floating', {'not-empty': email.length > 0})}>
            <span>Email address</span>
            <input type="email" name="email" value={email}
                   onChange={e => {
                     setEmail(e.target.value)
                   }}
                   onBlur={checkEmailAddress}/>
          </label>
          <div className="errormsg">
            {emailErrorMessage}
          </div>
        </div>
        <div className={clsx('input-row password ', passwordErrorMessage && HAS_ERROR_CLASS_NAME)}>
          <label className="floating">
            <span>Password</span>
            <input type="password" name="password"
                   onChange={e => {
                     setPassword(e.target.value)
                   }}/>
          </label>
          <div className="errormsg">
            {passwordErrorMessage}
          </div>
        </div>

        {inGDPR && (
          <div className="gdpr-options" style={{"display": "block"}}>

            <hr className="type-grade-separator"/>
            <div className="email-preferences">
              <div className="newsletter-options">
                <div className="input-row ">I want to hear from Education.com! Please send me messages about the
                  following (select all that apply):
                </div>
                {Object.keys(gdprPreferences).map((key) => {
                  return (
                    <div className="input-row" key={key}>
                      <label className="inline" htmlFor={key}><input id={key} name="email-preferences[]" value={key}
                                                       className="email-preferences" type="checkbox"/>
                        {gdprPreferences[key]}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        <div className="input-row registration__button">
          <input className="btn btn-primary" type="submit" value={registerButtonText}/>
        </div>
        <div className={clsx('input-row general ', generalErrorMessage && HAS_ERROR_CLASS_NAME)}>
          <div className="errormsg">
            {generalErrorMessage}
          </div>
        </div>
        <div className="registration__login">Already a member? <a onClick={(e) => {
          e.preventDefault();
          trackRegistration('opened', 'Viewed Registration Form', 'login');
          setAccount(email);
          setSection(SECTION_SWITCH);
        }}>Log In</a></div>

        <div className="registration__disclaimer">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>
            By clicking 'Create Account' above, I agree that I have read and agree to Education.com's <a
            href="/terms-of-use/">Terms of Use</a> and <a href="/privacy/">Privacy Policy</a>.
          </p>
          <p>
            Members receive Education.com emails. You can change email preferences in account settings.
          </p>
        </div>
      </form>
    </div>);
};

Signup.propTypes = {
  registerButtonText: PropTypes.string.isRequired,
  registerDescription: PropTypes.string.isRequired,
  registerTitle: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
  gdprPreferences: PropTypes.object.isRequired,
  inGDPR: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  defaultAccount: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Signup;
