import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Image from '../../components/Image';
import {setIs13OrOverCookie, setIsUnder13Cookie, dispatchCustomEvent, polyFillPadStart} from './util';
import {SECTION_AGE_GATE_FAIL} from "./index";
import {SECTION_AGE_GATE_FAIL_CREATE_ACCOUNT} from "./index";


const AgeGateEnterAge = ({setAgeGatePassed, setIsOpen, setAgeGateVerifyDOB, appOverrides, setSection}) => {
  const age = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const submitAge = () => {
    polyFillPadStart();
    const ageInput = age.current.value;

    if (ageInput === '0') {
      setErrorMessage('Please select a valid age');
      return false;
    }

    const userAge = parseInt(ageInput, 10);

    if (isNaN(userAge)) {
      setErrorMessage('Please select a valid age');
      return false;
    }

    if (userAge >= 13) {
      setIs13OrOverCookie();
      if (window.Edu && window.Edu.trackEvent) {
        window.Edu.trackEvent('Select age', {under13: false});
      }
      setAgeGatePassed(true);
      setAgeGateVerifyDOB(false);
    } else {
      setAgeGatePassed(false);
      setAgeGateVerifyDOB(false);
      setIsUnder13Cookie();
      if (window.Edu && window.Edu.trackEvent) {
        window.Edu.trackEvent('Select age', {under13: true});
      }
      dispatchCustomEvent('failedAgeGate');

      if (appOverrides.current.trigger !== 'topnav' && appOverrides.current.trigger !== 'game') {
        // This case is for an action trying to access a member-only feature, like downloading an article or adding
        // items to a Collection. So, since we can't create an account for someone younger than 13, we display the
        // "This feature is for members only" age gate.
        setSection(SECTION_AGE_GATE_FAIL);
      }
      else if (appOverrides.current.trigger === 'topnav') {
        // For failed AgeGateEnterAge action that comes from topnav (from pressing "Sign Up" button), instead of just
        // close out the Enter Age popover, we show an AgeGateFailCreateAccount.js popover page for more informative
        // feedback to the user.
        // EDENG-361.
        setSection(SECTION_AGE_GATE_FAIL_CREATE_ACCOUNT);
      }
      else {
        // This case is for an action coming from a game detail page (i.e. trigger = 'game'). Because we can't create an
        // account for someone younger than 13 but the games are still playable, we simply close the modal and allow
        // them to play the game.
        setIsOpen(false);
      }
    }

  };

  // RENDER
  return (
    <div className="screen-age-gate-enter-age-container">
      <div className="screen-age-gate-enter-age">
        <h4>How old are you?</h4>
        <div className="enter-age form">
          <div className="age-fields">
            <div className="input-row">
              <select aria-label="Select your age" className="age" ref={age}>
                <option value="0">Select your age</option>
                <option value="18">18 and older</option>
                <option value="17">17</option>
                <option value="16">16</option>
                <option value="15">15</option>
                <option value="14">14</option>
                <option value="13">13</option>
                <option value="12">12</option>
                <option value="11">11</option>
                <option value="10">10 and younger</option>
              </select>
            </div>
          </div>
          <div>
            <div className="age-button btn btn-primary btn-block" onClick={submitAge} role="button">
              Next
            </div>
            <div className="age-error-message">
              {errorMessage}
            </div>
          </div>
        </div>

        <Image alt="Roly" className="roly-kid" src="/files/static/reg-modal/roly-kid.png"/>
      </div>
    </div>
  )
};
AgeGateEnterAge.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  setAgeGatePassed: PropTypes.func.isRequired,
  setAgeGateVerifyDOB: PropTypes.func.isRequired,
  setSection: PropTypes.func.isRequired,
  appOverrides: PropTypes.object.isRequired,
};
export default AgeGateEnterAge;
