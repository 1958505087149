import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../../components/Button';
import {SECTION_LOGIN} from './index';
import {trackRegistration} from './util';

const FORGOT_VIEW = 'forgot';
const CONFIRM_VIEW = 'confirm';
const RESET_VIEW = 'reset';
const ERROR_VIEW = 'error';

const ResetPassword = ({setSection, setIsLoading, changePasswordModal, setIsOpen}) => {

  const [view, setView] = useState(FORGOT_VIEW);
  const [showChangedDiag, setShowChangedDiag] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState({"email-address": "", "general": "",});
  const [passwordError, setPasswordError] = useState({"email-verification": "", "password": "", "general": "",});
  const emailInputEl = useRef(null);
  const passwordInputEl = useRef(null);


  function populateFormWithQueryKey() {
    const bodyFormData = new FormData();
    bodyFormData.append('__call', 'PasswordReset');
    bodyFormData.append('action', 'checkKey');
    bodyFormData.append('key', window.Edu.queryString.key);
    bodyFormData.append('csrfToken', window.Edu.csrfToken);
    return bodyFormData;
  }

  function validateResetKeyFromQueryString() {
    if (window.Edu.queryString['reset-password'] && window.Edu.queryString.key) {
      setView(RESET_VIEW);
      const formDataWithKey = populateFormWithQueryKey();

      axios.post(`https://${window.location.host}${window.location.pathname}`,
        formDataWithKey,
        {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
          if (!response.data.success) {
            setView(ERROR_VIEW);
          }
        }
      ).catch(() => {
        setEmailError(Object.assign({}, emailError, {general: "An error occurred."}));
        setView(FORGOT_VIEW);
      });
    }
  }

  useEffect(() => {
    validateResetKeyFromQueryString();
  }, []);

  const resetErrors = () => {
    setEmailError({"email-address": "", "general": "",});
    setPasswordError({"email-verification": "", "password": "", "general": "",});
  };

  const validateEmailPresence = () => {
    resetErrors();
    if (email.length === 0) {
      setEmailError(Object.assign({}, emailError, {'email-address': 'Please enter your email address.'}));
      return false;
    }
    return true;
  };

  const validatePasswordPresence = () => {
    resetErrors();
    if (password.length === 0) {
      setPasswordError(Object.assign({}, passwordError, {password: "Please enter your password."}));
      return false;
    }
    return true;
  };


  const confirmEmailSentOrSetErrors = (response) => {
    if (response.data.success) {
      trackRegistration('reset-password email sent', 'Requested Password Reset');
      resetErrors();
      setEmail("");
      setView(CONFIRM_VIEW);
    } else if (response.data.error_type === 'email') {
      setEmailError(Object.assign({}, emailError, {
        'email-address': response.data.msg,
        'general': "Please fix the errors above in red and try again."
      }));
    } else if (response.data.error_type === 'general') {
      setEmailError(Object.assign({}, emailError, {'general': response.data.msg}));
    } else if (response.status === 0) {
      setEmailError({general: 'Unexpected Error', 'email-address': ''});
    } else {
      setEmailError(Object.assign({}, emailError, {'general': 'There was an error.'}));
    }
    setIsLoading(false);
  };

  function populateFormEmailReset() {
    const bodyFormData = new FormData();
    bodyFormData.append("__call", 'PasswordReset');
    bodyFormData.append('action', 'emailreset');
    bodyFormData.append('email', email);
    bodyFormData.append('csrfToken', window.Edu.csrfToken);
    return bodyFormData;
  }

  const submitEmailForReset = async (e) => {
    e.preventDefault();
    emailInputEl.current.focus();
    if (!validateEmailPresence(email)) return;

    const bodyFormData = populateFormEmailReset();
    setIsLoading(true);
    try {
      const response = await axios.post('?', bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}});
      confirmEmailSentOrSetErrors(response);
    }
    catch {
      confirmEmailSentOrSetErrors({data: {status: 0}});
    }
  };

  const refreshOnSuccessOrSetErrors = (response) => {
    setIsLoading(false);
    if (response.data.success) {
      trackRegistration('reset-password successful', 'Reset Password');
      window.location.href = '/';
    } else if (response.data.status === 0) {
      setPasswordError({general: 'Unexpected Error', 'email-address': ''});
    } else if (response.data.error_type === 'key') {
      setPasswordError(Object.assign({}, passwordError, {'email-verification': response.data.msg}));
    } else if (response.data.error_type === 'password') {
      setPasswordError(Object.assign({}, passwordError, {'password': response.data.msg}));
    }
    else {
      setPasswordError(Object.assign({}, passwordError, {'general': 'There was an error.'}));
    }
  };

  function populateFormForNewPassword() {
    const bodyFormData = new FormData();
    bodyFormData.append("__call", 'PasswordReset');
    bodyFormData.append('action', 'changepass');
    bodyFormData.append('key', window.Edu.queryString.key);
    bodyFormData.append('password', password);
    bodyFormData.append('csrfToken', window.Edu.csrfToken);
    return bodyFormData;
  }

  const submitPasswordChange = async (e) => {
    e.preventDefault();
    passwordInputEl.current.focus();

    if (!validatePasswordPresence()) return;

    const newPasswordFormData = populateFormForNewPassword();
    setIsLoading(true);

    try {
      const response = await axios.post(`https://${window.location.host}${window.location.pathname}`,
        newPasswordFormData,{headers: {'Content-Type': 'multipart/form-data'}});
      refreshOnSuccessOrSetErrors(response);
    } catch {
      refreshOnSuccessOrSetErrors({data: {status: 0}});
    }
  };

  // RENDER
  if (view === FORGOT_VIEW) {
    return (
      <div className="forgotPassword">
          <h3>Forgot your password?</h3>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>Please enter your email address and we'll send you instructions to reset your password.
            {!changePasswordModal &&
              <a onClick={() => {
                trackRegistration('opened', 'Viewed Registration Form', 'login');
                setSection(SECTION_LOGIN);
              }}> Go back to sign in page</a>
            }
          </p>
        <form className="form registration-form" onSubmit={submitEmailForReset}>
          <div className={clsx('input-row email-address ', emailError['email-address'] && 'has-error')}>
            <label className="floating">
              <span>Email address</span>
              <input type="email"
                     onChange={(e)=>{
                       setEmail(e.target.value);
                     }}
                     onBlur={(e)=> {
                       setEmail(e.target.value);
                     }}
                     ref={emailInputEl} autoFocus
                     required/>
            </label>
            <div className="errormsg">
              {emailError['email-address']}
            </div>
              <a className="email-changed" onClick={() => setShowChangedDiag(true)}>Has your email changed?</a>
          </div>

          {showChangedDiag &&
          <div className="email-changed-text">
            <p> If you no longer have access to the email address associated with your account, or if you are not receiving your password reset email, please contact <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.education.com">Customer
              Support</a> for help restoring access to your account.</p>
          </div>}

          <div className="buttons">
            <a className="btn btn-tertiary" onClick={() => {
              if (changePasswordModal) {
                setIsOpen(false);
              }
              else {
                trackRegistration('opened', 'Viewed Registration Form', 'login');
                setSection(SECTION_LOGIN);
              }
            }}>Cancel</a>
            <Button size="normal" type="submit">Send Email</Button>
          </div>

          <div className={clsx('input-row general ', emailError.general && 'has-error')}>
            <div className="errormsg">
              {emailError.general}
            </div>
          </div>
        </form>
      </div>
    );
  } else if (view === CONFIRM_VIEW) {

    return (
      <div className="confirm-email">
        <h3>Reset Password Email Sent</h3>
        <p className="subhead">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The email is on its way. Please allow a few minutes for it to arrive. Didn't receive the email?
          <a className="forgot" onClick={()=> {
            trackRegistration('Change Password', 'forgot password click', 'Clicked Forgot Password Link');
            setView(FORGOT_VIEW)}}> Go back and try again</a></p>
        <p className="description" style={{margin: "10px 0"}}/>
      </div>)

  } else if (view === RESET_VIEW) {
    return (
      <div className="reset-password">
        <div>
          <h3>Set/Reset Password</h3>
        </div>
        <div className="clear"/>
        <form className="form registration-form" onSubmit={submitPasswordChange}>
          <div className={clsx('input-row password ', passwordError.password && 'has-error')}>

            <label className="floating">
              <span>New Password</span>
              <input type="password" autoComplete="off" onChange={(e)=>{
                setPassword(e.target.value);
              }}
                     ref={passwordInputEl} autofocus required/>
            </label>
            <div className="errormsg">
              {passwordError.password}
            </div>
          </div>
          <div className={clsx('input-row email-verification ', passwordError['email-verification'] && 'has-error')}>
            <div className="errormsg">
              {passwordError['email-verification']}
            </div>
          </div>
          <Button type="submit">Change Password and Login</Button>
          <div className={clsx('input-row general ', passwordError.general && 'has-error')}>
            <div className="errormsg">
              {passwordError.general}
            </div>

          </div>
        </form>
      </div>)
  } else if (view === ERROR_VIEW) {
    return (
      <div className="error-message" style={{display: "block"}}>
        <h3>Set/Reset Password</h3>
        <div style={{"margin-top": "10px"}}>
          The key is expired or invalid. Please reset the password again.
        </div>
        <div style={{"text-align": "center", display: "block", padding: "20px 0 10px 0"}}>
          <a className="forgot-below forgot" onClick={()=>{setView(FORGOT_VIEW)}}>
            Reset Password </a>
        </div>
      </div>
    )
  }
  return null;
};
ResetPassword.propTypes = {
  setSection: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  changePasswordModal: PropTypes.bool,
};
ResetPassword.defaultProps = {
  changePasswordModal: false,
};
export default ResetPassword;
