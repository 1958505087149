import React, {Suspense} from 'react';
import axios from 'axios';
import ErrorBoundary from "./ErrorBoundary";
import PropTypes from 'prop-types';
import {trackRegistration} from './util';
import GoogleSSO from "../KidFrame/services/social/google";

const ERROR_MESSAGE_GENERIC = 'There was an error trying to log you in. Please try again.';
const SERVER_SIDE_ERROR = "That's not working right now. Please refresh your page or try again later.";
const EXISTING_LOGIN = true;
const GoogleOAuthProvider = React.lazy(() => import('@react-oauth/google').then(module => ({ default: module.GoogleOAuthProvider })));
const GoogleLogin = React.lazy(() => import('@react-oauth/google').then(module => ({ default: module.GoogleLogin })));

const GoogleLoginEdCom = ({onSuccess, onFailure, setIsLoading}) => {
  function populateLoginDataWithResponse(response) {
    const googleLoginFormData = new FormData();

    googleLoginFormData.append('code', response.code);
    googleLoginFormData.append('action', 'login-google');
    googleLoginFormData.append('__json', 'Modal_Registration');
    return googleLoginFormData;
  }

  const connectSuccess = (response) => {
    if (!response.code) return;
    setIsLoading(true);
    const googleLoginFormData = populateLoginDataWithResponse(response);
    axios.post('/', googleLoginFormData)
      .then(results => {
        if (results && results.data && results.data.status === "1") {
          const gaDimensions = 'ga_dimensions' in results.data ? results.data.ga_dimensions : null;
          if (results.data.newMember) {
            trackRegistration('signup success google', 'Registered', 'google', gaDimensions);
            onSuccess();
          } else {
            trackRegistration('login success google', 'Logged In', 'google', gaDimensions);
            onSuccess(EXISTING_LOGIN);
          }
        } else {
          onFailure(ERROR_MESSAGE_GENERIC);
        }
      })
      .catch(() => {
        onFailure(ERROR_MESSAGE_GENERIC);
      });
  };

  const connectFailure = (error) => {
    // GoogleLobinLib calls connectFailure when domain not authorized (or other connection error).
    // Errors are suppressed here because this function runs on component mount (user sees error with no action).
    onFailure({data: {serverSideError: true, msg: error.msg}});
  };

  const clickHandler = () => {
    // This function is ACTUALLY performing the login. We only use the GoogleLogin component
    // for display purposes.
    const config = {
    clientId: "283427801117-gcsiu9p714dc31684r15mdoks9vupn6a.apps.googleusercontent.com",
      sdkUrl: "https://accounts.google.com/gsi/client",
      scope: "profile https://www.googleapis.com/auth/userinfo.email"};

    const sso = new GoogleSSO(config);
    sso.login().then((response) => {
      connectSuccess(response)
    }).catch((error) => {
      connectFailure(error);
    });
  }

  return (
    <ErrorBoundary fallback={<div className="server-warning">{SERVER_SIDE_ERROR}</div>}>
      <Suspense fallback={<div>Loading...</div>}>
        <div className='edcom-google-wrapper' onClick={clickHandler}>
          <GoogleOAuthProvider clientId="283427801117-gcsiu9p714dc31684r15mdoks9vupn6a.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={connectSuccess}
              onError={() => {
                // eslint-disable-next-line no-console
                console.log('Login Failed');
              }}
              text="signin_with"
              width={200}
              type="standard"
              theme="filled_blue"
              size="large"
            />
          </GoogleOAuthProvider>
        </div>
      </Suspense>
    </ErrorBoundary>

  );

};
GoogleLoginEdCom.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired
};

export default GoogleLoginEdCom;
