import React, {Suspense} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {trackRegistration} from './util';
import ErrorBoundary from "./ErrorBoundary";

const ERROR_MESSAGE_GENERIC = 'There was an error trying to log you in. Please try again.';
const SERVER_SIDE_ERROR = "That's not working right now. Please refresh your page or try again later.";
const STATUS_CONNECTED = 'connected';
const REQUEST_TYPE = 'request_log_in';
const AUTH_TYPE = 'rerequest';
const SCOPE = ['public_profile', 'email'];
const EXISTING_LOGIN = true;
const FacebookLoginLib = React.lazy(() => import("react-facebook-login/dist/facebook-login-render-props"));
const FacebookLogin = ({onSuccess, onFailure, text, setIsLoading}) => {

  function populateFormWithResponse(response) {
    const facebookLoginFormData = new FormData();
    if (!response || !(response instanceof Object)) {
      return facebookLoginFormData;
    }

    facebookLoginFormData.append('authResponse[accessToken]', response.accessToken);
    facebookLoginFormData.append('authResponse[userID]', response.userID);
    facebookLoginFormData.append('authResponse[expiresIn]', response.expiresIn);
    facebookLoginFormData.append('authResponse[signedRequest]', response.signedRequest);
    facebookLoginFormData.append('authResponse[data_access_expiration_time]', response.data_access_expiration_time);
    facebookLoginFormData.append('authResponse[grantedScopes]', response.grantedScopes);
    facebookLoginFormData.append('status', STATUS_CONNECTED);
    facebookLoginFormData.append('requestType', REQUEST_TYPE);
    return facebookLoginFormData;
  }

  const responseCallback = (response) => {
    if (!response.accessToken) return;

    setIsLoading(true);
    const facebookLoginFormData = populateFormWithResponse(response);
    axios.post('/?__call=FacebookSSO', facebookLoginFormData)
      .then(results => {
        if (results.data && results.data.status === true) {
          const gaDimensions = 'ga_dimensions' in results.data ? results.data.ga_dimensions : null;
          if (results.data.newMember) {
            trackRegistration('signup success facebook', 'Registered', 'facebook', gaDimensions);
            onSuccess();
          } else {
            trackRegistration('login success facebook', 'Logged In', 'facebook', gaDimensions);
            onSuccess(EXISTING_LOGIN);
          }

        } else {
          if (results.data && results.data.message !== '') {
            onFailure(results.data.message);
          } else {
            onFailure(ERROR_MESSAGE_GENERIC);
          }
        }
      })
      .catch(()=> {
        onFailure(ERROR_MESSAGE_GENERIC);
      });
  };

  return (
    <ErrorBoundary fallback={<div className="server-warning">{SERVER_SIDE_ERROR}</div>}>
      <Suspense fallback={<div>Loading...</div>}>
        <FacebookLoginLib
          appId="116999881655243"
          callback={responseCallback}
          scope={SCOPE.join(',')}
          authType={AUTH_TYPE}
          returnScopes
          render={renderProps => (
            <div className="socialButtons__facebook font-primary-bold" onClick={()=> {
              trackRegistration('login with Facebook click', 'Clicked Facebook Login Button');
              renderProps.onClick()}} role="button">
              <img alt="" src="/files/static/reg-modal/facebook-logo.png"/>
              <span>{text}</span>
            </div>
          )}
        />
      </Suspense>
    </ErrorBoundary>

  );

};
FacebookLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
export default FacebookLogin;
