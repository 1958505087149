/**
 * A Promise which sleeps for the specified duration and then resolves
 * @param {number} ms - Time to sleep for in milliseconds
 * @returns {Promise<any>}
 */
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * @param {string} html
 * @returns {string}
 */
export function decodeHTML(html) {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;

    return txt.value;
}

/**
 *
 * @param {string} url - Asset url
 * @returns {string}
 */
export function prefixCDN(url) {
    if (window.Edu && window.Edu.prefixCDN) {
        return window.Edu.prefixCDN(url);
    }
    return url;
}

/**
 * Dispatches a custom event for communicating with React components
 * @param {string} eventName - Custom event name
 * @param {any} detail
 */
export function dispatch(eventName, detail) {
  eventName = 'edureact_'+eventName;

  let event;
  try {
    event = new CustomEvent(eventName, {detail});
  }
  catch(e) {
    // IE doesn't support CustomEvent as a constructor, so need to use this legacy method as a fallback
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(
      event,
      false,
      true,
      detail
    );
  }

  document.dispatchEvent(event);
}

/**
 * Listens for custom events
 * @param {string} eventName - Custom event name to listen for
 * @param {Function} originalCallback
 * @returns {Function}
 */
export function listen(eventName, originalCallback) {
    const name = `edureact_${eventName}`;

    const callback = (event) => {
        originalCallback(event.detail);
    };

    document.addEventListener(name, callback);

    return () => {
        document.removeEventListener(name, callback);
    };
}

/**
 * Opens the kid frame in a new window.
 * @param {string} href
 */
export function openKidFrame(href='/games/play/') {
  let h = (window.screen.availHeight) ? window.screen.availHeight : window.screen.height;
  let w = (window.screen.availWidth) ? window.screen.availWidth : window.screen.width;
  h = (h > 1250) ? 1250 : h;
  w = (w > 1920) ? 1920 : w;
  h -= 14;
  w -= 14;
  let gameWindow = window.open(href, 'gameswindow', 'scrollbars=no,menubar=no,location=no,status=no,toolbar=no,outerHeight='+h+',height='+h+',outerWidth='+w+',width='+w+',fullscreen=yes,left=0,top=0,resizable=yes');
  gameWindow.focus();
}

/**
 * Calculates the exponential delay for back-off.
 * @param {number} count - Retry attempt
 * @param {number} delay - Base delay
 * @returns {number}
 */
export function exponentialDelay(count=0, delay=500) {
  return Math.pow(2, count) * delay;
}

/**
 * Capitalizes the first letter of each word in a string.
 * @param {string} str
 * @returns {string}
 */
export function capitalize(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * Creates a React state reducer with action and state logger.
 * @param reducer
 * @returns {function(*=, *=): *}
 */
export function reducerWithLogger (reducer) {
  if(process.env.NODE_ENV === 'production') return reducer;

  return function(state, action) {
    console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state, action));
    return reducer(state, action);
  };
}
