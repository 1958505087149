import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import {SECTION_RESET} from './index';


const PASSWORD_LENGTH_ERROR = 'Must be at least 6 characters long';
const PASSWORD_CHANGE_ERROR = 'There was an error changing your password';
const CURRENT_PASSWORD_MISSING_ERROR = 'Please enter your current password';

const ChangePassword = ({setIsOpen, setIsLoading, setSection, setChangePasswordModal}) => {
  const [success, setSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    if (window.Edu && window.Edu.trackEvent) {
      window.Edu.trackEvent('Viewed Change Password Form', {}, {
        eventCategory: 'modal',
        eventAction: 'ChangePasswordModal: opened'
      })
    }
  }, []);

  const clearErrors = () => {
    setPasswordError('');
    setGeneralError('');
  };

  const setFormData = () => {
    const bodyFormData = new FormData();

    bodyFormData.append('new-password', newPassword);
    bodyFormData.append('old-password', currentPassword);

    return bodyFormData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    clearErrors();
    if (newPassword.length < 6) {
      setPasswordError(PASSWORD_LENGTH_ERROR);
      return;
    }
    if (currentPassword.length < 1) {
      setGeneralError(CURRENT_PASSWORD_MISSING_ERROR);
      return;
    }

    setIsLoading(true);
    const bodyFormData = setFormData();
    try {
      const response = await axios.post('/?__call=Auth&action=change-password', bodyFormData);

      if (response.data.status) {
        setSuccess(true);
        if (window.Edu && window.Edu.trackEvent) {
          window.Edu.trackEvent('Changed Password', {}, {
            eventCategory: 'modal',
            eventAction: 'ChangePasswordModal: success'
          });
        }
      } else {
        setGeneralError(response.data.error || PASSWORD_CHANGE_ERROR);
      }
    } catch {
      setPasswordError(PASSWORD_CHANGE_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  // RENDER
  return (
    <div className="change-password-react">
      <div className="title-container">
        <h3>Change password</h3>
      </div>
      <div className="clear" />
      <div className={clsx("success-message", !success && "hidden")}>
        <p>Password successfully updated.</p>
        <button className="close btn btn-secondary" onClick={() => {
          setIsOpen(false)
        }}>Close
        </button>
      </div>
      {!success && (
        <form className="form registration-form" noValidate>
          <div className="input-row old_password">
            <label className="floating">
              <span>Current password</span>
              <input type="password" onChange={(e)=>{setCurrentPassword(e.target.value);}} name="old_password" title="Must be at least 6 characters long"/>
            </label>
            <a className="forgot-below forgot" onClick={() => {
              setChangePasswordModal(true);
              setSection(SECTION_RESET)
            }}>Forgot password?</a>
          </div>
          <div className={clsx("input-row password", passwordError && "has-error")}>
            <label className="floating">
              <span>New password</span>
              <input type="password" onChange={(e)=> {setNewPassword(e.target.value);}} required pattern=".{6,}" name="password"
                     title="Must be at least 6 characters long"/>
            </label>
            <div className='errormsg'>{passwordError}</div>
          </div>
          <div className="buttons">
            <a className="btn btn-tertiary close" role="button" onClick={() => {
              clearErrors();
              setIsOpen(false);
            }}>Cancel</a>
            <button className="btn btn-primary" type="submit" onClick={handleSubmit}>Change password</button>
          </div>

          <div className={clsx("input-row general", generalError && "has-error")}>
            <div className="errormsg">{generalError}</div>
          </div>
        </form>)}
    </div>
  );

};
ChangePassword.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setSection: PropTypes.func.isRequired,
  setChangePasswordModal: PropTypes.func.isRequired,
};
export default ChangePassword;
