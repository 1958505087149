import {trackRegistration} from "./util";
import {SECTION_SIGNUP} from "./index";
import React from "react";
import PropTypes from "prop-types";

const NoEmailError = ({setSection}) => {

  return (
    <div>No account found with that email address. Do you want to
      <span> </span><a onClick={()=>{trackRegistration('opened', 'Viewed Registration Form', 'signup');
        setSection(SECTION_SIGNUP)}}>Create an Account</a>?
    </div>
  )
};
NoEmailError.propTypes = {
 setSection: PropTypes.func.isRequired,
};
export default NoEmailError;
