/*
An error boundary file which will throw an error message when certain component/library is
unable to import due to network failure
 */
import React from "react";
import {trackRegistration} from "./util";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    trackRegistration('Failed to login,', 'login failure');
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
