import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './SpinningWheel.less';

function SpinningWheel({fullScreen, className, ...otherProps}) {
  return (
    <div className={clsx('spinning-wheel', {'full-screen': fullScreen}, className)} {...otherProps}>
      <div className="background"/>
      <div className="spinner"/>
    </div>
  );
}

SpinningWheel.displayName = 'SpinningWheel';

SpinningWheel.propTypes = {
  fullScreen: PropTypes.bool
};
SpinningWheel.defaultProps = {
  fullScreen: false
};

export default SpinningWheel;
