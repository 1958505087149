import React from 'react';
import Image from '../../components/Image';

const AgeGateFailCreateAccount = () =>
  // RENDER
   (
    <div className="screen-age-gate-enter-age-fail-create-account">
      <div className="age-gate-fail-instructions-create-account">

        <div className="instruction">
          Please talk to a parent or guardian to sign up!
        </div>
      </div>
      <Image alt="Roly" className="roly-kid" src="/files/static/reg-modal/roly-kid.png"/>
    </div>
  );

export default AgeGateFailCreateAccount;
