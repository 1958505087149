let observe, unobserve;

// Modern browsers can use an IntersectionObserver to figure out when things come into view
const isBrowser = typeof window !== "undefined"

if (isBrowser){

    if(window.IntersectionObserver) {
        // key = DOM element, value = callback when element is visible
        const CALLBACK_MAP = new Map();

        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                // Outside of viewport still
                if(entry.intersectionRatio <= 0) {
                    return;
                }

                const callback = CALLBACK_MAP.get(entry.target);
                unobserve(entry.target);

                if(!callback) {
                    console.error("Cannot find callback for element",entry.target);
                    return;
                }

                callback();
            });
        },{rootMargin: '150px 0px', threshold: 0.01});

        observe = (el, callback) => {
            if(CALLBACK_MAP.has(el)) {
                console.error('element already being observed', el);
                return;
            }

            CALLBACK_MAP.set(el, callback);
            observer.observe(el);
        };

        unobserve = (el) => {
            if(!CALLBACK_MAP.has(el)) {
                return;
            }

            CALLBACK_MAP.delete(el);
            observer.unobserve(el);
        }
    }

}

export default {
    observe: observe,
    unobserve: unobserve
};