import PropTypes from 'prop-types';
import React from 'react';

const AccountLabel = ({account}) => {
  let label = '';
  const userType = account.userType ? ` ${account.userType}` : '';

  if (account.membershipType === 'pro') {
    label =
      (
        <div className="label__premium">
          <i className="icon-diamond-outline"/>
          <span>{`premium${userType}`}</span>
        </div>
      );
  }
    else if (account.userType === 'schooladmin') {
      label = (
        <div className="label__admin">
          <span>admin</span>
        </div>
      );
  } else {
    label = (
      <div className="label__basic">
        <span>{`basic${userType}`}</span>
      </div>
    );
  }
  return label;
};
AccountLabel.propTypes = {
  account: PropTypes.shape({userType: PropTypes.string}).isRequired,
};
export default AccountLabel;

