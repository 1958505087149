/**
 * Opens the kid frame in a new window.
 * @param {string} href
 */
export function openKidFrame(href='/games/play/') {
  let h = (screen.availHeight) ? screen.availHeight : screen.height;
  let w = (screen.availWidth) ? screen.availWidth : screen.width;
  h = (h > 1250) ? 1250 : h;
  w = (w > 1920) ? 1920 : w;
  h -= 14;
  w -= 14;
  let gameWindow = window.open(href, 'gameswindow', 'scrollbars=no,menubar=no,location=no,status=no,toolbar=no,outerHeight='+h+',height='+h+',outerWidth='+w+',width='+w+',fullscreen=yes,left=0,top=0,resizable=yes');
  gameWindow.focus();
}

export const loadScript = (src) => new Promise((resolve, reject) => {
  const js = document.createElement('script');
  js.src = src;
  js.onload = resolve;
  js.onerror = reject;
  document.head.appendChild(js);
});

/**
 * The assignment modals (both v1 and v2) don't handle certain characters (" and ') well due to being saved as
 * html entities. This helper function replaces them with the intended character.
 * @param {string} name - Student
 * @returns {string} cleanName
 */

export function fixStudentNameRender(name) {

  const cleanName = name.replace("&#39;", "'").replace("&#34;", "\""); // Replace HTML entities
  // For some reason, not chaining the replace fcns resulted in unexpected behavior

  return cleanName;
}
