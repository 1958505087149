import React, { useEffect, useRef, useState } from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import visibleObserver from '../../common/visibleObserver';
import {prefixCDN} from "../../common/util";

/**
 * When an image is lazyloaded, it can cause page reflows. This generates a placeholder src that is the same aspect
 * ratio of the image, so the element will remain the same size.
 * @param {number} width
 * @param {number} height
 * @param {number} displayWidth
 * @returns {string} The image src
 */
function getSrcPlaceholder(width, height, displayWidth) {
    if(displayWidth) {
        height = Math.ceil((displayWidth/width)*height);
        width = displayWidth;
    }

    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
}

/**
 * Uses the breakpoint to estimate the true px width of vw units
 * @param {number} vw (e.g. 50)
 * @param {number} breakpointWidth
 * @param {number} naturalWidth
 * @returns {number} the estimated px size
 */
function estimateVWWidth(vw, breakpointWidth, naturalWidth) {
    // If we don't know the breakpoint width, just assume it will be rendered at the natural width
    if(!breakpointWidth) {
        return naturalWidth;
    }

    // Otherwise, assume the screen is the size of the breakpoint width
    return Math.ceil(breakpointWidth * vw / 100);
}

/**
 * Uses the Fastly Image API to dynamically shrink and optimize an image src to any size
 * @param {string} originalSrc
 * @param {number} desiredWidth The size to resize to
 * @param {number} naturalWidth
 * @returns {string} The src of the resized image
 */
function getResizedSrc(originalSrc, desiredWidth, naturalWidth) {
    // No need to resize if we can just use the original src
    if(desiredWidth === naturalWidth) {
        return originalSrc;
    }
    // Can only scale down an image, not up. This is to preserve image quality.
    if(desiredWidth > naturalWidth) {
        throw Error('Cannot scale an image larger than the naturalWidth');
    }

    // Get rid of any existing query strings
    // TODO: is there ever a need to preserve query strings?
    originalSrc = originalSrc.replace(/\?.*$/,'');

    // Use Cloudflare Image Resizing options to set width
    // Source: https://developers.cloudflare.com/images/image-resizing/url-format#options
    originalSrc = originalSrc.replace('cdn.education.com', 'cdn.education.com/cdn-cgi/image/width=' + desiredWidth );

    return originalSrc;
}

/**
 * Size data object
 * @typedef {Object} Size
 * @property {string|null} comparisonOperator either "<=" or ">=". Null for the default size.
 * @property {number} breakpointWidth
 * @property {string} imageWidth image width including unit (either 'px' or 'vw')
 * @property {boolean} default true if this is the default fallback size when no other breakpoints match
 * @property {number} imagePxWidth image width in px (for widths specified in vw units, this value is estimated)
 */

/**
 * Sorts sizes array taking into account min/max width ordering rules
 * @param {Size[]} sizes
 * @returns {Size[]}
 */
function sortSizes(sizes) {
    // Sanity check: you can't have a ">=" rule that is smaller than any "<=" rule.
    // Example: both "<=1000" and ">=500". This is ambiguous and invalid.
    const largestLessThan = Math.max(...(sizes.filter(el=>el.comparisonOperator==='<=').map(el=>el.breakpointWidth)||[0]));
    const smallestGreaterThan = Math.min(...(sizes.filter(el=>el.comparisonOperator==='>=').map(el=>el.breakpointWidth)||[Infinity]));
    if(smallestGreaterThan < largestLessThan) {
        throw Error("Breakpoint rules are ambiguous");
    }

    return sizes.sort((a, b) => {
        // The default always goes at the very end
        if(a.default) return 1;
        if(b.default) return -1;

        // All "<=" go before all ">="
        if(a.comparisonOperator==='>=' && b.comparisonOperator==='<=') return 1;
        if(b.comparisonOperator==='>=' && a.comparisonOperator==='<=') return -1;

        // Given two "<=", the lower number is first
        if(a.comparisonOperator==='<=') {
            return a.breakpointWidth - b.breakpointWidth;
        }
        // Given two ">=", the higher number is first
        else {
            return b.breakpointWidth - a.breakpointWidth;
        }
    });
}

/**
 * Converts size data array into an HTML sizes prop
 * @param {Size[]} sizes
 * @returns {string} The HTML sizes prop
 */
function generateSizesProp(sizes) {
    return sizes.map((size) => {
        // The default size doesn't need a media query
        if(size.default) {
            return size.imageWidth;
        }

        const mediaQueryType = size.comparisonOperator==='>='?'min-width':'max-width';

        return `(${mediaQueryType}: ${size.breakpointWidth}px) ${size.imageWidth}`;
    }).join(',');
}

/**
 * Parses a breakpoint definition into component parts
 * @param {string} key The key in the breakpoints prop object (e.g. "<768")
 * @returns {{isDefault: boolean, comparisonOperator: null|string, breakpointWidth: number}} The components of the breakpoint key
 */
function parseBreakpointKey(key) {
    let comparisonOperator=null, breakpointWidth=0, isDefault=false;

    // A bunch of special keywords that all essentially mean: this is the default size when there's no breakpoint
    if(key.match(/^(else|all|any|default|>0|>=0)$/)) {
        isDefault = true;
    }
    // Otherwise, it's a comparison operator followed by a number (e.g. "<768")
    else {
        const match = key.match(/^(<|<=|>|>=)\s*([0-9]+)$/);
        if(!match) {
            throw Error("Breakpoint key must be comparison operator (<, <=, >, or >=) followed by number (e.g. '<768'). Received: "+key);
        }
        comparisonOperator = match[1];
        breakpointWidth = match[2];

        // Simplify comparisons so they are only ever '>=' or '<='
        if(comparisonOperator==='<') {
            breakpointWidth--;
            comparisonOperator='<=';
        }
        else if(comparisonOperator==='>') {
            breakpointWidth++;
            comparisonOperator='>=';
        }
    }

    return {
        comparisonOperator,
        breakpointWidth,
        isDefault
    };
}

/**
 * Parses a string image width into a standard format and px width
 * @param {string} value The value in the breakpoints prop object (e.g. "300px")
 * @param {number} breakpointWidth
 * @param {number} naturalWidth
 * @returns {{imageWidth: string, imagePxWidth: number}} The components of the breakpoint value
 */
function parseBreakpointValue(value, breakpointWidth, naturalWidth) {
    // Split value (image width) into the number and unit
    const width = 1*value.replace(/[^0-9]/g,'');
    const unit = value.replace(/[0-9 ]+/g,'') || 'px';

    // Determine pixel width
    let px;
    if(unit === 'px') {
        px = width;
    }
    else if(unit === 'vw') {
        // Estimate px width given the breakpoint
        px = estimateVWWidth(width, breakpointWidth, naturalWidth);
    }
    else {
        throw Error("Breakpoint values must be in either px or vw units. Received: "+value);
    }

    return {
        imageWidth: width+unit,
        imagePxWidth: px
    };
}

/**
 * Parses a single breakpoint key/value pair into size and srcSet
 * @param {string} key
 * @param {string} value
 * @param {string} originalSrc
 * @param {number} naturalWidth
 * @returns {{size:Size, srcSet: string}}
 */
function parseBreakpoint(key, value, originalSrc, naturalWidth) {
    const {comparisonOperator, breakpointWidth, isDefault} = parseBreakpointKey(key);
    const {imageWidth, imagePxWidth} = parseBreakpointValue(value, breakpointWidth, naturalWidth);

    return {
        size: {
            comparisonOperator,
            breakpointWidth,
            imageWidth,
            imagePxWidth,
            default: isDefault
        },
        srcSet: getResizedSrc(originalSrc, imagePxWidth, naturalWidth)+' '+imagePxWidth+'w'
    };
}

/**
 * Generates HTML sizes and srcSet properties from naturalWidth and breakpoint props
 * @param {Object} breakpoints. keys are the breakpoint (e.g. ">=768"), values are the image width (e.g. "200px")
 * @param {number} displayWidth
 * @param {number} naturalWidth
 * @param {string} originalSrc
 * @returns {null|{sizes:string|null, srcSet:string|null, src:string|null}}
 */
function getSizesAndSrcSet(breakpoints, displayWidth, naturalWidth, originalSrc) {
    if(!displayWidth && !breakpoints) {
        return null;
    }
    if(displayWidth && breakpoints) {
        throw Error("Can't specify both displayWidth and breakpoints.");
    }
    if((displayWidth || breakpoints) && !naturalWidth) {
        throw Error("naturalWidth is required if using displayWidth or breakpoints.");
    }

    // Relies on the Fastly image resizing API, so it doesn't work for 3rd party hosted images
    if(!originalSrc.match(/^(\/|https:\/\/cdn[0-9]*\.education\.com\/)/)) {
        console.error("Can only use breakpoints for images hosted by Education.com and served through the CDN",originalSrc);
    }

    // displayWidth is used for resizing images
    if(displayWidth) {
        // Resize the image using the Fastly API to the display width
        const src = getResizedSrc(originalSrc, displayWidth, naturalWidth);
        let srcSet=null;

        // If the natural width is at least 2x the display width, we can also include a version for retina displays
        if(displayWidth*2 <= naturalWidth) {
            srcSet = src+','+getResizedSrc(originalSrc,displayWidth*2,naturalWidth)+' 2x';
        }
        return {
            sizes: null,
            srcSet: srcSet,
            src: src
        };
    }

    // Parse each breakpoint
    let sizes = [], srcSets = [], includesNaturalWidth = false, sizesIncludesDefault = false;
    Object.entries(breakpoints).forEach(([key, value]) => {
        const {size, srcSet} = parseBreakpoint(key, value, originalSrc, naturalWidth);
        sizes.push(size);
        srcSets.push(srcSet);

        if(size.imagePxWidth === naturalWidth) {
            includesNaturalWidth = true;
        }
        if(size.default) {
            sizesIncludesDefault = true;
        }
    });

    // Add the natural width to srcSet if it's not already there
    if(!includesNaturalWidth) {
        srcSets.push(originalSrc+' '+naturalWidth+'w');
    }
    // Add a default size if it's not already there
    if(!sizesIncludesDefault) {
        sizes.push({
            comparisonOperator:null,
            breakpointWidth:0,
            default: true,
            imageWidth: naturalWidth+'px',
            imagePxWidth: naturalWidth
        });
    }

    return {
        sizes: generateSizesProp(sortSizes(sizes)),
        srcSet: srcSets.join(','),
        src: null
    };
}

const Image = ({lazy, naturalWidth, naturalHeight, displayWidth, alt, breakpoints, children, ...imgProps}) => {
    // Need a reference to the DOM <img> element so we can observe it for changes
    const imgRef = useRef(null);

    // Should the image load immediately or wait until visible?
    const [shouldLoad, setShouldLoad] = useState(!lazy || !visibleObserver.observe);

    // Use cdn for the image src when possible
    imgProps.src = prefixCDN(imgProps.src);

    // Override srcSet, sizes, and src from displayWidth/breakpoints props
    const {sizes, srcSet, src} = getSizesAndSrcSet(breakpoints, displayWidth, naturalWidth, imgProps.src)||{};
    imgProps.sizes = sizes || imgProps.sizes;
    imgProps.srcSet = srcSet || imgProps.srcSet;
    imgProps.src = src || imgProps.src;

    // Warn the user if they forgot to supply alt text for the image (only on dev)
    useEffect(() => {
        if(alt === null && process.env.NODE_ENV === 'development') {
            console.error('Make sure to define alt text for every react Image component.', imgRef.current);
        }
    }, [alt]);

    // For lazyload, watch for when the image is about to become visible
    useEffect(() => {
        if(!shouldLoad) {
            visibleObserver.observe(imgRef.current, () => {
                setShouldLoad(true);
            });

            // Cleanup by stopping observation
            return () => {
                visibleObserver.unobserve(imgRef.current);
            }
        }
    },[shouldLoad]);

    // If the image shouldn't be loaded yet, use fake data properties instead of src and srcSet
    if(!shouldLoad) {
        imgProps['data-src'] = imgProps.src;
        delete imgProps.src;

        // Placeholder src to maintain aspect ratio
        if(naturalWidth && naturalHeight) {
            imgProps.src = getSrcPlaceholder(naturalWidth, naturalHeight, displayWidth);
        }

        if(imgProps.srcSet) {
            imgProps['data-srcset'] = imgProps.srcSet;
            delete imgProps.srcSet
        }
    }

    return (
        <img alt={alt} {...imgProps} ref={imgRef} />
    )
};
Image.displayName='Image';
Image.propTypes = {
    /** e.g. "/files/static/blah.png" */
    src: PropTypes.string.isRequired,
    /** Make sure to define this for SEO and accessibility */
    alt: PropTypes.string,
    /** If true, waits to load image until it's about to be visible */
    lazy: PropTypes.bool,
    /** The pixel width of the raw image file. Used for responsive support and placeholder lazyload images */
    naturalWidth: PropTypes.number,
    /** The pixel height of the raw image file. Used for placeholder lazyload images */
    naturalHeight: PropTypes.number,
    /** The pixel width of the image when it's displayed. If it's responsive, use the 'breakpoints' prop instead */
    displayWidth: PropTypes.number,
    /** Describes responsive breakpoints and image widths (e.g. {"<768": "200px", "<1024": "400px", "else": "600px"} )*/
    breakpoints: PropTypes.object
};
Image.defaultProps = {
    alt: null,
    lazy: true,
    naturalWidth: undefined,
    naturalHeight: undefined,
    displayWidth: undefined,
    breakpoints: undefined
};

export default Image;
