import React, {useState, useEffect, useRef, Suspense} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import classNames from 'classnames';

import {SECTION_RESET, SECTION_SIGNUP, SECTION_SWITCH} from './index';
import {trackRegistration, IS_UNDER_13_COOKIE_NAME} from './util';
import NoEmailError from './NoEmailError';
import ReCAPTCHA from "react-google-recaptcha";
import ErrorBoundary from "./ErrorBoundary";

const PASSWORD_ERROR = 'That password is incorrect.';
const GENERAL_ERROR = 'There was an error signing in. Please refresh the page.';
const GDPR_ERROR = 'You need to enable cookies in order to log in.';
const EMPTY_EMAIL_ERROR = 'Please enter an email address.';
const EMPTY_PASSWORD_ERROR = 'Please enter a password.';
const EMPTY_RECAPTCHA = 'Please check the "I am not a robot" box above.';
const SERVER_SIDE_ERROR = "That's not working right now. Please refresh your page or try again later.";
const RESPONSE_CODE_TOO_MANY_REQUESTS = 429;
const RESPONSE_CODE_VERIFICATION_FAILED = 432;
const RECAPTCHA_SITE_KEY = "6LeyMfAUAAAAAHdUUd8sUtvTgW2SsVvKRpibXwqn";
const FacebookLogin = React.lazy(() => import("./FacebookLogin"));
const GoogleLoginEdCom = React.lazy(() => import("./GoogleLoginEdCom"));

const Login = ({
                 setSection, defaultAccount, setIsLoading, signInDescription,
                 signInTitle, generalError, setGeneralError, storedUsers, setAccount
               }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailChecks, setEmailChecks] = useState(0);
  const gdprInput = useRef(null);
  const passwordInput = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [gdprError, setGdprError] = useState("");
  const [requireCaptcha, setRequireCaptcha] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const cancelToken = useRef(null);
  const recaptchaRef = React.createRef();

  // default to account
  useEffect(() => {
    if (defaultAccount) {
      setEmail(defaultAccount);
      passwordInput.current.focus();
    }
    return (() => {
      cancelToken.current && cancelToken.current.cancel();
    })
  }, []);

  const checkEmailExists = () => {

    if (!email.length) {
      setEmailError('');
      return;
    }

    // limit to 20 email/mx lookups:
    if (emailChecks > 20) {
      return;
    }
    setEmailChecks(emailChecks + 1);
    // check the MX record:

    const {CancelToken} = axios;
    cancelToken.current = CancelToken.source();
    const bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("action", 'checkemail');
    bodyFormData.append("__json", 'Modal_Registration');
    bodyFormData.append("checknum", emailChecks);
    bodyFormData.append("csrfToken", window.Edu.csrfToken);


    axios.post('?', bodyFormData, {cancelToken: cancelToken.current.token})
      .then((response) => {
        if (!response.data.status || response.data.status === "0") {
          setEmailError(response.data.msg);
        } else {
          setEmailError("");
        }
      })
      .catch(() => {
          setEmailError("");
        }
      );
  };

  const loginSuccess = (gaDimensions = null) => {
    trackRegistration('login success', 'Logged In', null, gaDimensions);
    Cookies.remove(IS_UNDER_13_COOKIE_NAME);
    window.setTimeout(() => {
      window.location.reload();
    }, 100);
  };


  const loginFailure = (response) => {
    trackRegistration('Failed to login,', 'login failure');
    setIsLoading(false);
    
    if (response && response.data) {
      if (response.data.exists === false) {
        setEmailError(<NoEmailError setSection={setSection}/>);
      } else if (response.data.google) {
        setPasswordError(PASSWORD_ERROR);
        setGeneralError(response.data.msg);
      } else if (response.data.gdpr) {
        setGdprError(GDPR_ERROR);
      } else if (response.data.expiredSessionRefresh) {
        setGeneralError(response.data.msg)
      } else if (response.status === RESPONSE_CODE_TOO_MANY_REQUESTS) {
        setRequireCaptcha(true);
      } else if (response.status === RESPONSE_CODE_VERIFICATION_FAILED) {
        setGeneralError(response.data.msg)
      } else if (response.data.passwordError) {
        setPasswordError(PASSWORD_ERROR);
      } else if (response.data.serverSideError) {
        setGeneralError(SERVER_SIDE_ERROR);
      }
      else {
        setGeneralError(GENERAL_ERROR);
      }
    }
    // Error unrelated to a specific field
    else {
      setGeneralError(GENERAL_ERROR);
    }
    setIsLoading(false);
  };

  function validateEmailPresence() {
    if (email.length === 0) {
      setEmailError(EMPTY_EMAIL_ERROR);
      return false;
    }
    return true;
  }

  function validatePasswordPresence() {
    if (password.length === 0) {
      setPasswordError(EMPTY_PASSWORD_ERROR);
      return false;
    }
    return true;
  }

  function validateCaptcha() {
    if (!requireCaptcha) {
      return true;
    }
    if (captchaValue) {
      return true;
    }

    setGeneralError(EMPTY_RECAPTCHA);
    return false;
  }

  function resetRecaptcha() {
    setCaptchaValue(null);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }

  function updateCaptchaValue() {
    if (recaptchaRef.current) {
      setCaptchaValue(recaptchaRef.current.getValue());
    }
  }

  function populateFormDataLogin() {
    const bodyFormData = new FormData();
    bodyFormData.append("username", email);
    bodyFormData.append("password", password);
    bodyFormData.append("remember", 1);
    bodyFormData.append("__redirect", window.location.href);
    bodyFormData.append("jsfinished", 1);
    bodyFormData.append("gdprOptIn", gdprInput.current && gdprInput.current.checked ? 1 : 0);
    bodyFormData.append("csrfToken", window.Edu.csrfToken);
    bodyFormData.append("recaptcha",  captchaValue || '');
    return bodyFormData;
  }

  const login = async () => {

    if (!validateEmailPresence()) return;
    if (!validatePasswordPresence()) return;
    if (!validateCaptcha()) return;

    setIsLoading(true);
    const bodyFormData = populateFormDataLogin();
    resetRecaptcha();

    try {
      const response = await axios.post('/api/login', bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}});
      if (response.data.status === "1") {
        const gaDimensions = 'ga_dimensions' in response.data ? response.data.ga_dimensions : null;
        loginSuccess(gaDimensions);
      }
      // Failed to log in
      else {
        // Track the event only if they have 3 failed attempts to login (once)
        if ('loginAttempts' in response.data && response.data.loginAttempts === 3) {
          // Login failed 3 times; Save this data
          if (window.Edu && window.Edu.trackEvent) {
            window.Edu.trackEvent('Login attempt failed 3 times.', true);
          }
        }
        if ('isG' in response.data && response.data.isG) {
          // google user:
          loginFailure({data: {status: 0, exists: true, google: true, msg: response.data.msg}});
          return;
        }
        if ('gdpr' in response.data && response.data.gdpr) {
          // user is in gdpr and has not opted in
          loginFailure({data: {status: 0, exists: true, gdpr: true, msg: response.data.msg}});
          return;
        }

        // Check if the username/email is valid
        const checkBodyData = new FormData();
        checkBodyData.append('email', email);
        checkBodyData.append('csrfToken', window.Edu.csrfToken);

        const checkResponse = await axios.post('/api/check-email', checkBodyData);
        // Username/email is for a valid account
        if (checkResponse.data.status) {
          loginFailure({data: {status: 0, exists: true, passwordError: true}});
        }
        // No account found
        else {
          loginFailure({data: {status: 0, exists: false}});
        }
      }
    } catch (error) {

      if (error.response && error.response.data) {
        loginFailure(error.response);
        return;
      }

      loginFailure();
    }
  };



  const submitForm = (e) => {
    e.preventDefault();
    setGdprError("");

    login();
  };

  return (
    <div className="login">
      <h3>{signInTitle}</h3>
      <p className="login__description">{signInDescription}</p>

      <form className="form registration-form" onSubmit={submitForm} noValidate>
        <div className={clsx('input-row ', emailError && 'has-error')}>
          <label className={classNames('floating', {'not-empty': email.length > 0})}>
            <span>Email address</span>
            <input type="email" name="email"
                   value={email} onBlur={checkEmailExists} onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }} required/>
          </label>
          <div className="errormsg">
            {emailError}
          </div>
        </div>
        <div className={clsx('input-row ', passwordError && 'has-error')}>
          <label className="floating">
            <span>Password</span>
            <input type="password" name="password"
                   ref={passwordInput} onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
            }} required/>
          </label>
          <div className='errormsg'>{passwordError}</div>
          <a className="login__forgot" onClick={() => {
            trackRegistration('forgot-password link clicked');
            setSection(SECTION_RESET);
          }}><span> </span>Forgot Password?</a>
        </div>

        {requireCaptcha &&
          <div className="recaptcha-container">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={updateCaptchaValue}
            />
          </div>
        }

        <input type="submit" value="Log In" className="btn btn-primary" role="button"/>
        <div className={clsx('input-row general ', generalError && 'has-error')}>
          <div className="errormsg">{generalError}</div>
        </div>
        <p>Not an Education.com member yet? <a role="button" onClick={(e) => {
          e.preventDefault();
          trackRegistration('opened', 'Viewed Registration Form', 'signup');
          setAccount(email);
          setSection(SECTION_SIGNUP);
        }}>Create
          an Account</a></p>
      </form>

      <div className="login__or "><span>or</span></div>
      <div className="socialButtons social__login">
        <div className="buttonWrapper">
          <ErrorBoundary fallback={<div className="server-warning">{SERVER_SIDE_ERROR}</div>}>
            <Suspense fallback={<div>Loading...</div>}>
              <FacebookLogin text="Sign in with Facebook"
                             setIsLoading={setIsLoading}
                             onSuccess={loginSuccess}
                             onFailure={loginFailure}/>
              <GoogleLoginEdCom setIsLoading={setIsLoading}
                                onSuccess={loginSuccess}
                                onFailure={loginFailure}/>
            </Suspense>
          </ErrorBoundary>

        </div>
      </div>

      {storedUsers.current.length > 0 &&
      (<a role="button" onClick={() => {
        trackRegistration('choose account screen opened', 'Viewed Registration Form', 'choose-account');
        setSection(SECTION_SWITCH);
      }}> Switch accounts </a>)}
      <div id="status"/>
    </div>
  );

};

Login.propTypes = {
  storedUsers: PropTypes.oneOfType([
    PropTypes.object, // for legacy refs
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ]).isRequired,
  defaultAccount: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  signInDescription: PropTypes.string.isRequired,
  signInTitle: PropTypes.string.isRequired,
  generalError: PropTypes.string.isRequired,
  setGeneralError: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
};

export default Login;
