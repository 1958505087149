import React from 'react';
import Image from '../../components/Image';

const AgeGateFail = () => {

  // RENDER
  return (
    <div className="screen-age-gate-enter-age-fail">
      <div className="age-gate-fail-instructions">
        <Image alt="Tutu" className="tutu-pre-teen" src="/files/static/reg-modal/TuTu-PreTeen.png"/>
          <div>
            Oops! This feature is for members only.
          </div>
      </div>
    </div>
  )
};
export default AgeGateFail;
